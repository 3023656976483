import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li>
                    <a href="https://github.com/Stumbave" className="icon alt fa-github" target="_blank">
                        <span className="label">GitHub</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/stumbave" className="icon alt fa-linkedin" target="_blank">
                        <span className="label">LinkedIn</span>
                    </a>
                </li>
            </ul>
            <ul className="copyright">
                <li>&copy; Stumbave Limited</li>
                <li className="right">Design: <a href="https://html5up.net" target="_blank">HTML5 UP</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
